import { GridColDef } from '@material-ui/data-grid';

export const ColumnEmpresa: GridColDef = {
  headerName: 'Empresa',
  field: 'empresa',
  width: 600,
};

export const ColumnURLPowerBIAccountingAnalysis: GridColDef = {
  field: 'url_powerbi_accouting_analysis',
  headerName: 'URL Power BI - Accounting Analysis',
  width: 2000,
};

export const ColumnURLPowerBIFinancialaccounts: GridColDef = {
  field: 'url_powerbi_financial_accounts',
  headerName: 'URL Power BI - Financial Accounts',
  width: 2000,
};

export const ColumnURLPowerBIHumanResources: GridColDef = {
  field: 'url_powerbi_human_resources',
  headerName: 'URL Power BI - Human Resources',
  width: 2000,
};

export const ColumnURLPowerBICostAnalysis: GridColDef = {
  field: 'url_powerbi_cost_analysis',
  headerName: 'URL Power BI - Reports BI',
  width: 2000,
};

export const ColumnId: GridColDef = {
  headerName: '#',
  field: 'id',
  hide: true,
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
  disableColumnMenu: true,
};
