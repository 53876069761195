import React from 'react';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { ContainerChip } from './styles';

interface IComponentDescriptionSystemKindProps {
  kind: number;
}

const useStylesAccountingAnalysis = makeStyles({
  root: {
    borderColor: '#006699',
    color: '#006699',
  },
});

const useStylesFinancialAccounts = makeStyles({
  root: {
    borderColor: '#669933',
    color: '#669933',
  },
});

const useStylesCostAnalysis = makeStyles({
  root: {
    borderColor: '#FF530D',
    color: '#FF530D',
  },
});

const useStylesHumanResources = makeStyles({
  root: {
    borderColor: '#9400D3',
    color: '#9400D3',
  },
});

const ComponentDescriptionSystemKind: React.FC<IComponentDescriptionSystemKindProps> = ({ kind }) => {
  const accountingAnalysis = 'Accounting Analysis';
  const financialAccounts = 'Financial Accounts';
  const costAnalysis = 'Reports BI';
  const humanResources = 'Human Resources';

  const cssAccountingAnalysis = useStylesAccountingAnalysis();
  const cssFinancialAccounts = useStylesFinancialAccounts();
  const cssCostAnalysis = useStylesCostAnalysis();
  const cssHumanResources = useStylesHumanResources();

  return (
    <>
      {(kind & 1) > 0 && (
        <ContainerChip>
          <Chip label={accountingAnalysis} variant="outlined" className={cssAccountingAnalysis.root} />
        </ContainerChip>
      )}

      {(kind & 2) > 0 && (
        <ContainerChip>
          <Chip label={financialAccounts} variant="outlined" className={cssFinancialAccounts.root} />
        </ContainerChip>
      )}

      {(kind & 4) > 0 && (
        <ContainerChip>
          <Chip label={costAnalysis} variant="outlined" className={cssCostAnalysis.root} />
        </ContainerChip>
      )}

      {(kind & 8) > 0 && (
        <ContainerChip>
          <Chip label={humanResources} variant="outlined" className={cssHumanResources.root} />
        </ContainerChip>
      )}
    </>
  );
};

export default ComponentDescriptionSystemKind;
