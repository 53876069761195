import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ButtonDialog from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiOutlineSearch,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import getListUpdate from '../../utils/getListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import InputComum from '../../components/InputComum';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import {
  ColumnEmpresa,
  ColumnId,
  ColumnURLPowerBIAccountingAnalysis,
  ColumnURLPowerBIFinancialaccounts,
  ColumnURLPowerBIHumanResources,
  ColumnURLPowerBICostAnalysis,
} from './GridColumns';

import IUsersCompany from './IUsersCompany';
import ICompany from './ICompany';
import IUsers from './IUsers';
import ComponentDescriptionSystemKind from './ComponentDescriptionSystemKind';

import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  ContainerFilterSelect,
  SpanFiltro,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  ContainerFieldCnpj,
  ContainerFieldCheckbox,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const emptyUsersCompany: IUsersCompany = {
  acao: '',
  id: '',
  id_user: '',
  id_company: '',
  id_license: '',
  active: true,
  cnpj: '',
  empresa: '',
  url_powerbi_accouting_analysis: '',
  url_powerbi_cost_analysis: '',
  url_powerbi_financial_accounts: '',
  url_powerbi_human_resources: '',
  system_kind: 1,
  accountinganalysis: false,
  financialaccounts: false,
  costanalysis: false,
  humanresources: false,
};

const idaccounting = 'accountingAnalysis';
const idfinancial = 'financialAccounts';
const idcost = 'costAnalysis';
const idhumanresources = 'humanResources';
const idfieldSelectCompany = 'a000001jnihfd63n8';
const idfieldSelectUser = 'a0051swdf515533r';
const titulo = 'P E R M I S S Õ E S';

const UsersCompany: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [selectedUser, setSelectedUser] = useState<IUsers>();
  const [listUsers, setListUsers] = useState<IUsers[]>([]);
  const [listCompanies, setListCompanies] = useState<ICompany[]>([]);
  const [usersCompany, setUsersCompany] = useState<IUsersCompany>();
  const [listUsersCompany, setListUsersCompany] = useState<IUsersCompany[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(true);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [usersCompanyIndex, setUsersCompanyIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteUsersCompany, setIdDeleteUsersCompany] = useState<string>('');
  const [openFilterUser, setOpenFilterUser] = React.useState(true);
  const [textFiltro, setTextFiltro] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [disableUrlPowerBi, setDisableUrlPowerBi] = useState<boolean>(false);
  const [disableUrlPowerBiFinancialAccounts, setDisableUrlPowerBiFinancialAccounts] = useState<boolean>(false);
  const [disableUrlPowerBiCostAnalysis, setDisableUrlPowerBiCostAnalysis] = useState<boolean>(false);
  const [disableUrlPowerBiHumanResources, setDisableUrlPowerBiHumanResources] = useState<boolean>(false);
  const [disableUrlInputPowerBi, setDisableUrlInputPowerBi] = useState<boolean>(false);
  const [disableUrlInputPowerBiFinancialAccounts, setDisableUrlInputPowerBiFinancialAccounts] = useState<boolean>(
    false,
  );
  const [disableUrlInputPowerBiCostAnalysis, setDisableUrlInputPowerBiCostAnalysis] = useState<boolean>(false);
  const [disableUrlInputPowerBiHumanResources, setDisableUrlInputPowerBiHumanResources] = useState<boolean>(false);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const handleCloseFilterUser = useCallback(() => {
    setOpenFilterUser(false);
  }, []);

  const defineUrls = useCallback((inCompany: ICompany): void => {
    if (inCompany) {
      setDisableUrlPowerBi((inCompany.system_kind & 1) === 0);
      setDisableUrlPowerBiFinancialAccounts((inCompany.system_kind & 2) === 0);
      setDisableUrlPowerBiCostAnalysis((inCompany.system_kind & 4) === 0);
      setDisableUrlPowerBiHumanResources((inCompany.system_kind & 8) === 0);

      setDisableUrlInputPowerBi((inCompany.system_kind & 1) === 0);
      setDisableUrlInputPowerBiFinancialAccounts((inCompany.system_kind & 2) === 0);
      setDisableUrlInputPowerBiCostAnalysis((inCompany.system_kind & 4) === 0);
      setDisableUrlInputPowerBiHumanResources((inCompany.system_kind & 8) === 0);

      setInputValue('url_powerbi_accouting_analysis', (inCompany.system_kind & 1) > 0 ? inCompany.url_powerbi : '');

      setInputValue(
        'url_powerbi_financial_accounts',
        (inCompany.system_kind & 2) > 0 ? inCompany.url_powerbi_financial_accounts : '',
      );

      setInputValue(
        'url_powerbi_cost_analysis',
        (inCompany.system_kind & 4) > 0 ? inCompany.url_powerbi_cost_analysis : '',
      );

      setInputValue(
        'url_powerbi_human_resources',
        (inCompany.system_kind & 8) > 0 ? inCompany.url_powerbi_human_resources : '',
      );
    }
  }, []);

  const defineUsersCompany = useCallback(
    async (data: IUsersCompany) => {
      const localData = {
        ...data,
        accountinganalysis: (data.system_kind & 1) !== 0,
        financialaccounts: (data.system_kind & 2) !== 0,
        costanalysis: (data.system_kind & 4) !== 0,
        humanresources: (data.system_kind & 8) !== 0,
      };

      setUsersCompany(localData);
      formRef.current?.setData(localData);
      const findCompany = listCompanies.find(itemList => itemList.id === localData.id_company);

      if (findCompany && localData.id === '') {
        defineUrls(findCompany);
      }

      if (findCompany) {
        setDisableUrlPowerBi((findCompany.system_kind & 1) === 0);
        setDisableUrlPowerBiFinancialAccounts((findCompany.system_kind & 2) === 0);
        setDisableUrlPowerBiCostAnalysis((findCompany.system_kind & 4) === 0);
        setDisableUrlPowerBiHumanResources((findCompany.system_kind & 8) === 0);
      }

      setDisableUrlInputPowerBi((localData.system_kind & 1) === 0);
      setDisableUrlInputPowerBiFinancialAccounts((localData.system_kind & 2) === 0);
      setDisableUrlInputPowerBiCostAnalysis((localData.system_kind & 4) === 0);
      setDisableUrlInputPowerBiHumanResources((localData.system_kind & 8) === 0);
    },
    [defineUrls, listCompanies],
  );

  const onNewRegister = useCallback(async () => {
    defineUsersCompany(emptyUsersCompany);
  }, [defineUsersCompany]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localUsersCompany = params.row as IUsersCompany;

      setUsersCompanyIndex(listUsersCompany.indexOf(localUsersCompany));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineUsersCompany(localUsersCompany);
    },
    [listUsersCompany, defineUsersCompany],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseUsersCompany: IUsersCompany | undefined) => {
      if (baseUsersCompany !== undefined) {
        const newUsersCompany: IUsersCompany = {
          acao: baseUsersCompany.acao,
          id: '',
          id_license: baseUsersCompany.id_license,
          id_user: baseUsersCompany.id_user,
          id_company: baseUsersCompany.id_company,
          active: baseUsersCompany.active,
          cnpj: baseUsersCompany.cnpj,
          empresa: baseUsersCompany.empresa,
          system_kind: baseUsersCompany.system_kind,
          accountinganalysis: baseUsersCompany.accountinganalysis,
          financialaccounts: baseUsersCompany.financialaccounts,
          humanresources: baseUsersCompany.humanresources,
          costanalysis: baseUsersCompany.costanalysis,
          url_powerbi_accouting_analysis: baseUsersCompany.url_powerbi_accouting_analysis,
          url_powerbi_cost_analysis: baseUsersCompany.url_powerbi_cost_analysis,
          url_powerbi_financial_accounts: baseUsersCompany.url_powerbi_financial_accounts,
          url_powerbi_human_resources: baseUsersCompany.url_powerbi_human_resources,
        };

        defineUsersCompany(newUsersCompany);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineUsersCompany],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteUsersCompany(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as IUsersCompany)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as IUsersCompany).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnSystemKindDescription: GridColDef = {
    headerName: 'Sistema',
    field: 'system_kind',
    width: 500,
    renderCell: (params: GridCellParams) => {
      let valor = 0;
      if (params.value) {
        valor = parseInt(params.value.toLocaleString(), 0);
      }

      return <ComponentDescriptionSystemKind kind={valor} />;
    },
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnEmpresa,
    ColumnSystemKindDescription,
    ColumnURLPowerBIAccountingAnalysis,
    ColumnURLPowerBIFinancialaccounts,
    ColumnURLPowerBIHumanResources,
    ColumnURLPowerBICostAnalysis,
    ColumnId,
  ];

  const getCompanies = useCallback(async () => {
    const response = await api.get<ICompany[]>(`/company/${user.license.id}`);
    setListCompanies(response.data);
    defineUrls(response.data[0]);
  }, [user, defineUrls]);

  const getUserCompanies = useCallback(
    async (email: string) => {
      const response = await api.get<IUsersCompany[]>('/usercompany/user/all', {
        params: {
          license: user.license.id,
          email,
        },
      });

      setListUsersCompany(response.data);

      if (rowToEdit !== null) {
        const findEditUsersCompany = response.data.find(item => item.id === rowToEdit);

        if (findEditUsersCompany) {
          defineUsersCompany(findEditUsersCompany);
          setUsersCompanyIndex(response.data.indexOf(findEditUsersCompany));
          setHiddenCadastrar(false);
          setHiddenPesquisar(true);
          setRowToEdit(null);
        }
      }
    },
    [user, defineUsersCompany, rowToEdit],
  );

  const handleClick = useCallback((id: string): void => {
    if (id === 'filtro') {
      setOpenFilterUser(id === 'filtro');
      return;
    }

    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  }, []);

  const handleOnFilter = useCallback(async () => {
    const fieldSelect = document.getElementById(idfieldSelectUser);

    if (fieldSelect) {
      const id = (fieldSelect as HTMLSelectElement).value;
      const findUser = listUsers.find(item => id === item.id);
      if (findUser) {
        setOpenLoading(true);
        getUserCompanies(findUser.email);
        setSelectedUser(findUser);
        setTextFiltro(`   Filtrado por: ${findUser.name} [${findUser.email}]`);
        setOpenLoading(false);
      }
    }

    setOpenFilterUser(false);
    handleClick('pesquisar');
  }, [listUsers, getUserCompanies, handleClick]);

  const getUserLicenses = useCallback(async () => {
    const response = await api.get<IUsers[]>(`/userlicense/all/${user.license.id}`);

    const list = response.data.filter(item => item.email !== 'suporte@assessortech.com.br');

    setListUsers(list);
    if (list && list[0]) {
      setSelectedUser(list[0]);
      setTextFiltro(`   Filtrado por: ${list[0].name} [${list[0].email}]`);
    }
  }, [user]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getCompanies();
    await getUserLicenses();

    setOpenLoading(false);
  }, [getCompanies, getUserLicenses]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const getDescricaoCompanySelectOption = useCallback((value: ICompany): string => {
    if (!value) return '';

    return `${value.code.trim()}-${value.name.trim()}[${value.cnpj.trim()}]`;
  }, []);

  const getCompanySelectOption = useCallback(
    (id: string): ICompany => {
      return listCompanies.filter(item => item.id === id)[0];
    },
    [listCompanies],
  );

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/usercompany/${idDeleteUsersCompany}`);

    setListUsersCompany(OldValue => OldValue.filter(item => item.id !== idDeleteUsersCompany));

    defineUsersCompany(emptyUsersCompany);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteUsersCompany, addToast, defineUsersCompany]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListUsersCompany(OldValue => OldValue.filter(item => item.id !== itemId));

          return api.delete(`/usercompany/${itemId}`);
        }),
      );

      defineUsersCompany(emptyUsersCompany);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineUsersCompany, selectedIds]);

  const onNextRegister = useCallback(async () => {
    if (usersCompanyIndex !== undefined) {
      if (usersCompanyIndex < listUsersCompany.length - 1) {
        const localIndex = usersCompanyIndex + 1;
        setUsersCompanyIndex(localIndex);
        const findUsersCompany = listUsersCompany[localIndex];
        if (findUsersCompany) {
          defineUsersCompany(findUsersCompany);
        }
      }
    }
  }, [usersCompanyIndex, listUsersCompany, defineUsersCompany]);

  const onFirstRegister = useCallback(async () => {
    if (listUsersCompany.length > 0) {
      setUsersCompanyIndex(0);
      const findUsersCompany = listUsersCompany[0];
      if (findUsersCompany) {
        defineUsersCompany(findUsersCompany);
      }
    }
  }, [listUsersCompany, defineUsersCompany]);

  const onLastRegister = useCallback(async () => {
    if (listUsersCompany.length > 0) {
      setUsersCompanyIndex(listUsersCompany.length - 1);
      const findUsersCompany = listUsersCompany[listUsersCompany.length - 1];
      if (findUsersCompany) {
        defineUsersCompany(findUsersCompany);
      }
    }
  }, [listUsersCompany, defineUsersCompany]);

  const onPriorRegister = useCallback(async () => {
    if (usersCompanyIndex !== undefined) {
      if (usersCompanyIndex > 0) {
        const localIndex = usersCompanyIndex - 1;
        setUsersCompanyIndex(localIndex);
        const findUsersCompany = listUsersCompany[localIndex];
        if (findUsersCompany) {
          defineUsersCompany(findUsersCompany);
        }
      }
    }
  }, [usersCompanyIndex, listUsersCompany, defineUsersCompany]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (usersCompanyIndex !== undefined) {
      const findUsersCompany = listUsersCompany[usersCompanyIndex];
      if (findUsersCompany) {
        defineUsersCompany(findUsersCompany);
      }
    }

    onNewRegister();
  }, [usersCompanyIndex, listUsersCompany, onNewRegister, defineUsersCompany]);

  const setInputValue = (id: string, value: string): void => {
    const fieldInput = document.getElementById(id);
    if (fieldInput) {
      (fieldInput as HTMLInputElement).value = value;
    }
  };

  const handleOnChangeCompany = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const findCompany = listCompanies.find(itemList => itemList.id === event.target.value);
    if (findCompany && usersCompany) {
      defineUsersCompany({
        acao: '',
        accountinganalysis: (findCompany.system_kind & 1) !== 0,
        financialaccounts: (findCompany.system_kind & 2) !== 0,
        costanalysis: (findCompany.system_kind & 4) !== 0,
        humanresources: (findCompany.system_kind & 8) !== 0,
        active: true,
        url_powerbi_accouting_analysis: findCompany.url_powerbi,
        url_powerbi_cost_analysis: findCompany.url_powerbi_cost_analysis,
        url_powerbi_financial_accounts: findCompany.url_powerbi_financial_accounts,
        url_powerbi_human_resources: findCompany.url_powerbi_human_resources,
        cnpj: findCompany.cnpj,
        empresa: findCompany.name,
        id_company: findCompany.id,
        system_kind: findCompany.system_kind,
        id_license: '',
        id_user: '',
        id: '',
      });
      defineUrls(findCompany);
    }
  };

  const handleSubmit = useCallback(
    async (data: IUsersCompany, { reset }) => {
      if (licencaInvalida()) return;

      const fieldSelect = document.getElementById(idfieldSelectCompany);
      if (fieldSelect) {
        const id = (fieldSelect as HTMLSelectElement).value;
        data.id_company = id;
        data.empresa = getDescricaoCompanySelectOption(getCompanySelectOption(id));
      }

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          id_company: Yup.string().required('Empresa é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);

        let system_kind_temp = 0;
        const fieldAccounting = document.getElementById(idaccounting);
        if (fieldAccounting) {
          if ((fieldAccounting as HTMLInputElement).checked) {
            system_kind_temp += 1;
            data.accountinganalysis = true;
          }
        }

        const fieldFinancial = document.getElementById(idfinancial);
        if (fieldFinancial) {
          if ((fieldFinancial as HTMLInputElement).checked) {
            system_kind_temp += 2;
            data.financialaccounts = true;
          }
        }

        const fieldCost = document.getElementById(idcost);
        if (fieldCost) {
          if ((fieldCost as HTMLInputElement).checked) {
            system_kind_temp += 4;
            data.costanalysis = true;
          }
        }

        const fieldHumanResources = document.getElementById(idhumanresources);
        if (fieldHumanResources) {
          if ((fieldHumanResources as HTMLInputElement).checked) {
            system_kind_temp += 8;
            data.humanresources = true;
          }
        }
        data.system_kind = system_kind_temp;
        if (data.id === '') {
          const formData = {
            id_license: user.license.id,
            id_company: data.id_company,
            url_powerbi_accouting_analysis: data.url_powerbi_accouting_analysis,
            url_powerbi_cost_analysis: data.url_powerbi_cost_analysis,
            url_powerbi_financial_accounts: data.url_powerbi_financial_accounts,
            url_powerbi_human_resources: data.url_powerbi_human_resources,
            system_kind: data.system_kind,
            costanalysis: data.costanalysis,
            accountinganalysis: data.accountinganalysis,
            financialaccounts: data.financialaccounts,
            humanresources: data.humanresources,
            id_user: selectedUser?.id_user,
            active: true,
            cnpj: user.empresa.cnpj,
          };

          await api.post(`/usercompany`, formData);

          setListUsersCompany(oldLicenses => [...oldLicenses, data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          const formEdit = {
            id_license: user.license.id,
            id_company: data.id_company,
            url_powerbi_accouting_analysis: data.url_powerbi_accouting_analysis,
            url_powerbi_cost_analysis: data.url_powerbi_cost_analysis,
            url_powerbi_financial_accounts: data.url_powerbi_financial_accounts,
            url_powerbi_human_resources: data.url_powerbi_human_resources,
            system_kind: data.system_kind,
            costanalysis: data.costanalysis,
            accountinganalysis: data.accountinganalysis,
            financialaccounts: data.financialaccounts,
            humanresources: data.humanresources,
            id_user: selectedUser?.id_user,
            active: true,
            cnpj: user.empresa.cnpj,
          };

          await api.put(`/usercompany/${data.id}`, formEdit);

          setListUsersCompany(getListUpdate(listUsersCompany, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [
      licencaInvalida,
      listUsersCompany,
      user,
      selectedUser,
      addToast,
      getDescricaoCompanySelectOption,
      getCompanySelectOption,
    ],
  );

  const clearInputValue = (id: string): void => {
    const fieldInput = document.getElementById(id);
    if (fieldInput) {
      (fieldInput as HTMLInputElement).value = '';
    }
  };

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>
          <TabButton id="filtro" isActive={false} onClick={() => handleClick('filtro')}>
            <AiOutlineSearch />
          </TabButton>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteUsersCompany('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>
      <SpanFiltro>{textFiltro}</SpanFiltro>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listUsersCompany}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={usersCompany}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(usersCompany)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(usersCompany ? usersCompany.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFields field="T100">
                <span>Empresa:</span>
                <Select id={idfieldSelectCompany} name="id_company" onChange={handleOnChangeCompany}>
                  {listCompanies.map(company => (
                    <option key={company.id} value={company.id}>
                      {getDescricaoCompanySelectOption(company)}
                    </option>
                  ))}
                </Select>
              </ContainerFields>

              <ContainerFieldCnpj>
                <ContainerFieldCheckbox field="T400px" className={disableUrlPowerBi ? 'desabilitado' : ''}>
                  <Checkbox
                    id={idaccounting}
                    name="accountinganalysis"
                    disabled={disableUrlPowerBi}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = !(e.target as HTMLInputElement).checked;
                      setDisableUrlInputPowerBi(localValue);
                      if (localValue) {
                        clearInputValue('url_powerbi_accouting_analysis');
                      }
                    }}
                  />
                  <span>Accounting Analysis</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox
                  field="T400px"
                  className={disableUrlPowerBiFinancialAccounts ? 'desabilitado field2' : 'field2'}
                >
                  <Checkbox
                    id={idfinancial}
                    name="financialaccounts"
                    disabled={disableUrlPowerBiFinancialAccounts}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = !(e.target as HTMLInputElement).checked;
                      setDisableUrlInputPowerBiFinancialAccounts(localValue);
                      if (localValue) {
                        clearInputValue('url_powerbi_financial_accounts');
                      }
                    }}
                  />
                  <span>Financial Accounts</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox
                  field="T400px"
                  className={disableUrlPowerBiHumanResources ? 'desabilitado field2' : 'field2'}
                >
                  <Checkbox
                    id={idhumanresources}
                    name="humanresources"
                    disabled={disableUrlPowerBiHumanResources}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = !(e.target as HTMLInputElement).checked;
                      setDisableUrlInputPowerBiHumanResources(localValue);
                      if (localValue) {
                        clearInputValue('url_powerbi_human_resources');
                      }
                    }}
                  />
                  <span>Human Resources</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox
                  field="T300px"
                  className={disableUrlPowerBiCostAnalysis ? 'desabilitado field2' : 'field2'}
                >
                  <Checkbox
                    id={idcost}
                    name="costanalysis"
                    disabled={disableUrlPowerBiCostAnalysis}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = !(e.target as HTMLInputElement).checked;
                      setDisableUrlInputPowerBiCostAnalysis(localValue);
                      if (localValue) {
                        clearInputValue('url_powerbi_cost_analysis');
                      }
                    }}
                  />
                  <span>Reports BI</span>
                </ContainerFieldCheckbox>
              </ContainerFieldCnpj>

              <ContainerFields field="T100" className={disableUrlInputPowerBi ? 'desabilitado' : ''}>
                <span>URL Power BI - Accounting Analysis</span>
                <InputComum
                  id="url_powerbi_accouting_analysis"
                  name="url_powerbi_accouting_analysis"
                  disabled={disableUrlInputPowerBi}
                />
              </ContainerFields>

              <ContainerFields field="T100" className={disableUrlInputPowerBiFinancialAccounts ? 'desabilitado' : ''}>
                <span>URL Power BI - Financial Accounts</span>
                <InputComum
                  id="url_powerbi_financial_accounts"
                  name="url_powerbi_financial_accounts"
                  disabled={disableUrlInputPowerBiFinancialAccounts}
                />
              </ContainerFields>

              <ContainerFields field="T100" className={disableUrlInputPowerBiHumanResources ? 'desabilitado' : ''}>
                <span>URL Power BI - Human Resources</span>
                <InputComum
                  id="url_powerbi_human_resources"
                  name="url_powerbi_human_resources"
                  disabled={disableUrlInputPowerBiHumanResources}
                />
              </ContainerFields>

              <ContainerFields field="T100" className={disableUrlInputPowerBiCostAnalysis ? 'desabilitado' : ''}>
                <span>URL Power BI - Reports BI</span>
                <InputComum
                  id="url_powerbi_cost_analysis"
                  name="url_powerbi_cost_analysis"
                  disabled={disableUrlInputPowerBiCostAnalysis}
                />
              </ContainerFields>

              <ContainerFields field="id">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <Dialog
        open={openFilterUser}
        onClose={handleCloseFilterUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filtro</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Usuário:</DialogContentText>
          <ContainerFields field="T100">
            <ContainerFilterSelect>
              <select id={idfieldSelectUser}>
                {listUsers.map(itemUser => (
                  <option key={itemUser.id} value={itemUser.id}>
                    {itemUser.name} [{itemUser.email}]
                  </option>
                ))}
              </select>
            </ContainerFilterSelect>
          </ContainerFields>
        </DialogContent>
        <DialogActions>
          <ButtonDialog onClick={handleCloseFilterUser} color="primary" autoFocus>
            Cancelar
          </ButtonDialog>
          <ButtonDialog onClick={handleOnFilter} color="primary">
            Filtrar
          </ButtonDialog>
        </DialogActions>
      </Dialog>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default UsersCompany;
