import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import InputDate from '../../components/InputDate';
import Checkbox from '../../components/Checkbox';
import Header from '../../components/Header';
import Select from '../../components/Select';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import cnpj from '../../utils/getValidationCNPJ';
import getValidationErrors from '../../utils/getValidationErrors';
import getListUpdate from '../../utils/getListUpdate';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnCnpj,
  ColumnDescricao,
  ColumnEmail,
  ColumnQuantidadeCnpj,
  ColumnCnpjCadastrados,
  ColumnObservacao,
  ColumnBloqueado,
  ColumnMensagemCliente,
  ColumnToken,
  ColumnURLPowerBIAccountingAnalysis,
  ColumnURLPowerBIFinancialaccounts,
  ColumnURLPowerBIHumanResources,
  ColumnURLPowerBICostAnalysis,
  ColumnRLSPowerBI,
  ColumnId,
  ColumnNroSerie,
  ColumnTipoLicenca,
  ColumnIdLicenciadoAgrupador,
} from './GridColumns';

import ILicense, { emptyLicenseConfig } from './ILicense';
import ICompany from './ICompany';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  ContainerFieldCheckbox,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const optionsTipoLicenca = [
  { value: '1-Ferramentas Assessortech', label: '1-Ferramentas Assessortech' },
  { value: '2-Vinculado a outro licenciado', label: '2-Vinculado a outro licenciado' },
  { value: '9-Gerenciamento contratos', label: '9-Gerenciamento contratos' },
];

const LicenseConfig: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [licenseConfig, setLicenseConfig] = useState<ILicense>();
  const [listLicenseConfig, setListLicenseConfig] = useState<ILicense[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(false);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [licenseConfigIndex, setLicenseConfigIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteLicenseConfig, setIdDeleteLicenseConfig] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [isIdLicenciadoAgrupadorVisible, setIsIdLicenciadoAgrupadorVisible] = useState(false);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineLicenseConfig = useCallback(async (data: ILicense) => {
    setLicenseConfig(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineLicenseConfig(emptyLicenseConfig);
  }, [defineLicenseConfig]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localLicenseConfig = params.row as ILicense;

      setLicenseConfigIndex(listLicenseConfig.indexOf(localLicenseConfig));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineLicenseConfig(localLicenseConfig);
    },
    [listLicenseConfig, defineLicenseConfig],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseLicenseConfig: ILicense | undefined) => {
      if (baseLicenseConfig !== undefined) {
        const newLicenseConfig: ILicense = {
          acao: baseLicenseConfig.acao,
          blocked: false,
          rls_power_bi: baseLicenseConfig.rls_power_bi,
          cnpj: '',
          id: '',
          data_inicio: baseLicenseConfig.data_inicio,
          data_fim: baseLicenseConfig.data_fim,
          descricao: '',
          email_admin_user: baseLicenseConfig.email_admin_user,
          mensagem_cliente: baseLicenseConfig.mensagem_cliente,
          observacao: baseLicenseConfig.observacao,
          token: '',
          quantidade_cnpj: baseLicenseConfig.quantidade_cnpj,
          updated_user: '',
          url_powerbi: baseLicenseConfig.url_powerbi,
          url_powerbi_cost_analysis: baseLicenseConfig.url_powerbi_cost_analysis,
          url_powerbi_financial_accounts: baseLicenseConfig.url_powerbi_financial_accounts,
          url_powerbi_human_resources: baseLicenseConfig.url_powerbi_human_resources,
          nro_serie: baseLicenseConfig.nro_serie,
          tipo_licenca: baseLicenseConfig.tipo_licenca,
          id_licenciado_agrupador: baseLicenseConfig.id_licenciado_agrupador,
          quantidadeCnpjUtilizada: 0,
          quantidadeCnpjDisponivel: 0,
          listaEmpresas: [],
        };

        defineLicenseConfig(newLicenseConfig);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineLicenseConfig],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteLicenseConfig(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(params.row as ILicense)}>
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as ILicense).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnDataInicio: GridColDef = {
    field: 'data_inicio',
    headerName: 'Data início',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataFim: GridColDef = {
    field: 'data_fim',
    headerName: 'Data fim',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;

      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnCnpjRestantes: GridColDef = {
    field: 'quantidadeCnpjDisponivel',
    headerName: 'CNPJ disponíveis',
    width: 200,
    type: 'number',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <span />;

      if ((params.value as number) > 0) {
        return <strong style={{ color: '#006400' }}>{params.value}</strong>;
      }

      if ((params.value as number) < 0) {
        return <strong style={{ color: '#FF0000' }}>{params.value}</strong>;
      }

      return <span>{params.value}</span>;
    },
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnCnpj,
    ColumnDescricao,
    ColumnQuantidadeCnpj,
    ColumnCnpjCadastrados,
    ColumnCnpjRestantes,
    ColumnDataInicio,
    ColumnDataFim,
    ColumnEmail,
    ColumnNroSerie,
    ColumnTipoLicenca,
    ColumnIdLicenciadoAgrupador,
    ColumnObservacao,
    ColumnBloqueado,
    ColumnRLSPowerBI,
    ColumnMensagemCliente,
    ColumnToken,
    ColumnURLPowerBIAccountingAnalysis,
    ColumnURLPowerBIFinancialaccounts,
    ColumnURLPowerBIHumanResources,
    ColumnURLPowerBICostAnalysis,
    ColumnId,
  ];

  const getLicenses = useCallback(async () => {
    const response = await api.get<ILicense[]>('/licenseconfig/all');
    const responseCompany = await api.get<ICompany[]>('/company/all/license/admin');
    const localList = response.data;
    responseCompany.data.forEach(element => {
      const findLicense = localList.find(itemList => itemList.id === element.id_license);
      if (findLicense) {
        const numberTemp = findLicense.quantidadeCnpjUtilizada ? findLicense.quantidadeCnpjUtilizada : 0;
        findLicense.quantidadeCnpjUtilizada = numberTemp + 1;
        findLicense.quantidadeCnpjDisponivel = findLicense.quantidade_cnpj - findLicense.quantidadeCnpjUtilizada;
        // if (findLicense.listaEmpresas) {
        //  findLicense.listaEmpresas.push(element);
        // } else {
        //   findLicense.listaEmpresas = [element];
        // }
      }
    });

    setListLicenseConfig(localList);

    if (rowToEdit !== null) {
      const findEditLicenseConfig = localList.find(item => item.id === rowToEdit);

      if (findEditLicenseConfig) {
        defineLicenseConfig(findEditLicenseConfig);
        setLicenseConfigIndex(localList.indexOf(findEditLicenseConfig));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [defineLicenseConfig, rowToEdit]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);
    await getLicenses();
    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [getLicenses]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    await api.delete(`/licenseconfig/${idDeleteLicenseConfig}`);

    setListLicenseConfig(oldValues => oldValues.filter(item => item.id !== idDeleteLicenseConfig));

    defineLicenseConfig(emptyLicenseConfig);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteLicenseConfig, addToast, defineLicenseConfig]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListLicenseConfig(oldValues => oldValues.filter(item => item.id !== itemId));

          return api.delete(`/licenseconfig/${itemId}`);
        }),
      );

      defineLicenseConfig(emptyLicenseConfig);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineLicenseConfig, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (licenseConfigIndex !== undefined) {
      if (licenseConfigIndex < listLicenseConfig.length - 1) {
        const localIndex = licenseConfigIndex + 1;
        setLicenseConfigIndex(localIndex);
        const findLicenseConfig = listLicenseConfig[localIndex];
        if (findLicenseConfig) {
          defineLicenseConfig(findLicenseConfig);
        }
      }
    }
  }, [licenseConfigIndex, listLicenseConfig, defineLicenseConfig]);

  const onFirstRegister = useCallback(async () => {
    if (listLicenseConfig.length > 0) {
      setLicenseConfigIndex(0);
      const findLicenseConfig = listLicenseConfig[0];
      if (findLicenseConfig) {
        defineLicenseConfig(findLicenseConfig);
      }
    }
  }, [listLicenseConfig, defineLicenseConfig]);

  const onLastRegister = useCallback(async () => {
    if (listLicenseConfig.length > 0) {
      setLicenseConfigIndex(listLicenseConfig.length - 1);
      const findLicenseConfig = listLicenseConfig[listLicenseConfig.length - 1];
      if (findLicenseConfig) {
        defineLicenseConfig(findLicenseConfig);
      }
    }
  }, [listLicenseConfig, defineLicenseConfig]);

  const onPriorRegister = useCallback(async () => {
    if (licenseConfigIndex !== undefined) {
      if (licenseConfigIndex > 0) {
        const localIndex = licenseConfigIndex - 1;
        setLicenseConfigIndex(localIndex);
        const findLicenseConfig = listLicenseConfig[localIndex];
        if (findLicenseConfig) {
          defineLicenseConfig(findLicenseConfig);
        }
      }
    }
  }, [licenseConfigIndex, listLicenseConfig, defineLicenseConfig]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (licenseConfigIndex !== undefined) {
      const findLicenseConfig = listLicenseConfig[licenseConfigIndex];
      if (findLicenseConfig) {
        defineLicenseConfig(findLicenseConfig);
      }
    }

    onNewRegister();
  }, [licenseConfigIndex, listLicenseConfig, onNewRegister, defineLicenseConfig]);

  const handleSubmit = useCallback(
    async (data: ILicense, { reset }) => {
      if (licencaInvalida()) return;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          blocked: Yup.boolean(),
          cnpj: Yup.string()
            .required('CNPJ é obrigatório')
            .test('cnpj-valid', 'CNPJ inválido', value => {
              const isValid = cnpj.isValid(value as string);
              if (!isValid) {
                return false;
              }

              return true;
            })
            .test('exists-licenseConfig', 'CNPJ já existe em outra empresa', value => {
              const findLicenseConfig = listLicenseConfig.find(itemList => itemList.cnpj === value);
              if (findLicenseConfig && findLicenseConfig.id !== data.id) {
                return false;
              }
              return true;
            }),
          data_inicio: Yup.date().label('Data inválida').required('Data de início é obrigatória'),
          email_admin_user: Yup.string().email().required('e-mail é obrigatório'),
          descricao: Yup.string().required('Razão social é obrigatória'),
          quantidade_cnpj: Yup.number()
            .moreThan(0, `Quantidade de CNPJ's deve ser maior que 0`)
            .required(`Quantidade de CNPJ's é obrigatória`),
          id_licenciado_agrupador: Yup.string().test('license-valid', 'Licença inválida', value => {
            if (!value) return true;

            const findLicenseConfig = listLicenseConfig.find(itemList => itemList.id === value);
            if (findLicenseConfig && findLicenseConfig.id !== data.id) {
              return true;
            }
            return false;
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id === '') {
          const formData = {
            cnpj: data.cnpj,
            email_admin_user: data.email_admin_user,
            descricao: data.descricao,
            observacao: data.observacao,
            quantidade_cnpj: data.quantidade_cnpj,
            data_inicio: new Date(`${data.data_inicio.substring(0, 10)}T12:00:00`),
            data_fim: data.data_fim ? new Date(`${data.data_fim.substring(0, 10)}T12:00:00`) : null,
            blocked: data.blocked,
            rls_power_bi: data.rls_power_bi,
            mensagem_cliente: data.mensagem_cliente,
            url_powerbi: data.url_powerbi,
            url_powerbi_financial_accounts: data.url_powerbi_financial_accounts,
            url_powerbi_human_resources: data.url_powerbi_human_resources,
            url_powerbi_cost_analysis: data.url_powerbi_cost_analysis,
            nro_serie: data.nro_serie,
            tipo_licenca: data.tipo_licenca,
            id_licenciado_agrupador: data.id_licenciado_agrupador,
            created_user: user.id,
          };

          const response = await api.post(`/licenseConfig`, formData);
          setListLicenseConfig(oldLicenses => [...oldLicenses, response.data]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/licenseConfig/${data.id}`, data);
          setListLicenseConfig(getListUpdate(listLicenseConfig, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
      }
    },
    [licencaInvalida, listLicenseConfig, user, addToast],
  );

  const handleSelectTipoLicenca = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const localTipo: string = event.target.value as string;
    setIsIdLicenciadoAgrupadorVisible(localTipo === '2-Vinculado a outro licenciado');
    const localFieldInput = document.getElementById('wearf972354ih');
    if (localFieldInput) {
      (localFieldInput as HTMLInputElement).value = '';
    }
  };

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteLicenseConfig('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>L I C E N C I A M E N T O</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listLicenseConfig}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={licenseConfig}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(licenseConfig)}>
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(licenseConfig ? licenseConfig.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <ContainerFieldCheckbox>
                    <Checkbox name="blocked" />
                    <span>Bloqueado</span>
                  </ContainerFieldCheckbox>
                </Grid>
                <Grid item xs={6}>
                  <ContainerFieldCheckbox>
                    <Checkbox name="rls_power_bi" />
                    <span>RLS Power BI</span>
                  </ContainerFieldCheckbox>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <ContainerFields field="T100">
                    <span>CNPJ:</span>
                    <InputComum autoFocus name="cnpj" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <ContainerFields field="T100">
                    <span>Nro série:</span>
                    <InputComum autoFocus name="nro_serie" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <ContainerFields field="T100">
                    <span>Tipo licença:</span>
                    <Select
                      name="tipo_licenca"
                      defaultValue="1-Ferramentas Assessortech"
                      onChange={handleSelectTipoLicenca}
                    >
                      {optionsTipoLicenca.map(itemList => (
                        <option key={itemList.value} value={itemList.value}>
                          {itemList.label}
                        </option>
                      ))}
                    </Select>
                  </ContainerFields>
                </Grid>
                <Grid item xs={12}>
                  <ContainerFields field="T100">
                    <span>Razão social:</span>
                    <InputComum autoFocus name="descricao" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12}>
                  <ContainerFields field="T100">
                    <span>E-mail administrador:</span>
                    <InputComum autoFocus name="email_admin_user" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12} sm={isIdLicenciadoAgrupadorVisible ? 6 : 12}>
                  <ContainerFields field="T100">
                    <span>Quantidade CNPJ:</span>
                    <InputComum name="quantidade_cnpj" type="number" min="0" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12} sm={6} hidden={!isIdLicenciadoAgrupadorVisible}>
                  <ContainerFields field="T100">
                    <span>Id licenciado agrupador:</span>
                    <InputComum id="wearf972354ih" name="id_licenciado_agrupador" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ContainerFields field="T100">
                    <span>Data início:</span>
                    <InputDate name="data_inicio" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ContainerFields field="T100">
                    <span>Data fim:</span>
                    <InputDate name="data_fim" />
                  </ContainerFields>
                </Grid>

                <Grid item xs={12}>
                  <ContainerFields field="T100">
                    <span>Observação:</span>
                    <InputComum name="observacao" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12}>
                  <ContainerFields field="T100">
                    <span>Mensagem Cliente:</span>
                    <InputComum name="mensagem_cliente" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12}>
                  <ContainerFields field="T100">
                    <span>URL Power BI - Accounting Analysis</span>
                    <InputComum name="url_powerbi" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12}>
                  <ContainerFields field="T100">
                    <span>URL Power BI - Financial Accounts</span>
                    <InputComum name="url_powerbi_financial_accounts" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12}>
                  <ContainerFields field="T100">
                    <span>URL Power BI - Human Resources</span>
                    <InputComum name="url_powerbi_human_resources" />
                  </ContainerFields>
                </Grid>
                <Grid item xs={12}>
                  <ContainerFields field="T100">
                    <span>URL Power BI - Reports BI</span>
                    <InputComum name="url_powerbi_cost_analysis" />
                  </ContainerFields>
                </Grid>
              </Grid>

              <ContainerFields field="TID">
                <InputComum name="id" hidden />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default LicenseConfig;
