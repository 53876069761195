import { GridColDef } from '@material-ui/data-grid';

export const ColumnCode: GridColDef = {
  field: 'code',
  headerName: 'Código',
  width: 150,
};

export const ColumnCNPJ: GridColDef = {
  field: 'cnpj',
  headerName: 'CNPJ',
  width: 150,
};

export const ColumnIE: GridColDef = {
  field: 'ie',
  headerName: 'Inscrição Estatual',
  width: 200,
};

export const ColumnCNPJEstabTot: GridColDef = {
  field: 'cnpj_estab_tot',
  headerName: 'Usar Configurações do CNPJ',
  width: 270,
};

export const ColumnName: GridColDef = {
  field: 'name',
  headerName: 'Razão social',
  width: 400,
};

export const ColumnActive: GridColDef = {
  field: 'active',
  headerName: 'Ativa',
  width: 150,
  type: 'boolean',
};

export const ColumnURLPowerBIAccountingAnalysis: GridColDef = {
  field: 'url_powerbi',
  headerName: 'URL Power BI - Accounting Analysis',
  width: 2000,
};

export const ColumnURLPowerBIFinancialaccounts: GridColDef = {
  field: 'url_powerbi_financial_accounts',
  headerName: 'URL Power BI - Financial Accounts',
  width: 2000,
};

export const ColumnURLPowerBIHumanResources: GridColDef = {
  field: 'url_powerbi_human_resources',
  headerName: 'URL Power BI - Human Resources',
  width: 2000,
};

export const ColumnURLPowerBICostAnalysis: GridColDef = {
  field: 'url_powerbi_cost_analysis',
  headerName: 'URL Power BI - Reports BI',
  width: 2000,
};

export const ColumnPeriodoAnaliseAnalitica: GridColDef = {
  field: 'periodo_analise_analitica',
  headerName: 'Período Análise Analítica',
  width: 300,
};

export const ColumnId: GridColDef = {
  headerName: 'id_company',
  field: 'id',
  width: 400,
  editable: false,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
};
