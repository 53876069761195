import React from 'react';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { ContainerChip } from './styles';

interface IComponentDescriptionSystemKindProps {
  kind: string;
}

const useStylesAccountingAnalysis = makeStyles({
  root: {
    borderColor: '#006699',
    color: '#006699',
  },
});

const useStylesFinancialAccounts = makeStyles({
  root: {
    borderColor: '#669933',
    color: '#669933',
  },
});

const useStylesCostAnalysis = makeStyles({
  root: {
    borderColor: '#FF530D',
    color: '#FF530D',
  },
});

const useStylesHumanResources = makeStyles({
  root: {
    borderColor: '#9400D3',
    color: '#9400D3',
  },
});

const ComponentDescriptionSystemKind: React.FC<IComponentDescriptionSystemKindProps> = ({ kind }) => {
  const accountingAnalysis = 'Accounting Analysis';
  const financialAccounts = 'Financial Accounts';
  const costAnalysis = 'Reports BI';
  const humanResources = 'Human Resources';

  const cssAccountingAnalysis = useStylesAccountingAnalysis();
  const cssFinancialAccounts = useStylesFinancialAccounts();
  const cssCostAnalysis = useStylesCostAnalysis();
  const cssHumanResources = useStylesHumanResources();

  if (kind === accountingAnalysis) {
    return (
      <ContainerChip>
        <Chip label={accountingAnalysis} variant="outlined" className={cssAccountingAnalysis.root} />
      </ContainerChip>
    );
  }

  if (kind === financialAccounts) {
    return (
      <ContainerChip>
        <Chip label={financialAccounts} variant="outlined" className={cssFinancialAccounts.root} />
      </ContainerChip>
    );
  }

  if (kind === costAnalysis) {
    return (
      <ContainerChip>
        <Chip label={costAnalysis} variant="outlined" className={cssCostAnalysis.root} />
      </ContainerChip>
    );
  }

  if (kind === humanResources) {
    return (
      <ContainerChip>
        <Chip label={humanResources} variant="outlined" className={cssHumanResources.root} />
      </ContainerChip>
    );
  }

  return <div />;
};

export default ComponentDescriptionSystemKind;
