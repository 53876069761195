import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineWhatsApp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import InputID from '../../components/InputID';
import Checkbox from '../../components/Checkbox';
// import Select from '../../components/Select';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';
import Header from '../../components/Header';

import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';

import cnpj from '../../utils/getValidationCNPJ';
import getListUpdate from '../../utils/getListUpdate';
import { getPathGrid } from '../../utils/storagePath';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ColumnCode,
  ColumnCNPJ,
  ColumnIE,
  ColumnCNPJEstabTot,
  ColumnName,
  ColumnActive,
  ColumnURLPowerBIAccountingAnalysis,
  ColumnURLPowerBIFinancialaccounts,
  ColumnURLPowerBIHumanResources,
  ColumnURLPowerBICostAnalysis,
  // ColumnPeriodoAnaliseAnalitica,
  ColumnId,
} from './GridColumns';

import ICompany from './ICompany';
import ICompanyLicenseDTO from './ICompanyLicenseDTO';
import ComponentDescriptionSystemKind from './ComponentDescriptionSystemKind';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  ContainerFieldCnpj,
  ContainerFieldCheckbox,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  TabButtonWhatsApp,
  SpanLicencasNumber,
  SpanLicencas,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const emptyCompany: ICompany = {
  acao: '',
  active: true,
  cnpj: '',
  ie: '',
  cnpj_estab_tot: '',
  code: '',
  name: '',
  system_kind: 1,
  accountinganalysis: true,
  financialaccounts: false,
  costanalysis: false,
  humanresources: false,
  url_powerbi: '',
  url_powerbi_cost_analysis: '',
  url_powerbi_financial_accounts: '',
  url_powerbi_human_resources: '',
  id: '',
  created_user: '',
  licence: '',
  periodo_analise_analitica: '0-Ano Corrente',
};

const idaccounting = 'accountingAnalysis';
const idfinancial = 'financialAccounts';
const idcost = 'costAnalysis';
const idhumanresources = 'humanResources';

// const options_periodo_analise_analitica = [
//   { value: '0-Ano Corrente', label: '0-Ano Corrente' },
//   {
//     value: '1-Ano Corrente + Ano Anterior',
//     label: '1-Ano Corrente + Ano Anterior',
//   },
//   {
//     value: '2-Ano Corrente + 2 Anos Anteriores',
//     label: '2-Ano Corrente + 2 Anos Anteriores',
//   },
//   {
//     value: '3-Ano Corrente + 3 Anos Anteriores',
//     label: '3-Ano Corrente + 3 Anos Anteriores',
//   },
//   {
//     value: '4-Ano Corrente + 4 Anos Anteriores',
//     label: '4-Ano Corrente + 4 Anos Anteriores',
//   },
//   {
//     value: '5-Ano Corrente + 5 Anos Anteriores',
//     label: '5-Ano Corrente + 5 Anos Anteriores',
//   },
//   { value: '9-Todos os Períodos', label: '9-Todos os Períodos' },
// ];

const Company: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [company, setCompany] = useState<ICompany>();
  const [listCompanies, setListCompanies] = useState<ICompany[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(false);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [companyIndex, setCompanyIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteCompany, setIdDeleteCompany] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  const [disableUrlPowerBi, setDisableUrlPowerBi] = useState<boolean>(false);
  const [disableUrlPowerBiFinancialAccounts, setDisableUrlPowerBiFinancialAccounts] = useState<boolean>(false);
  const [disableUrlPowerBiCostAnalysis, setDisableUrlPowerBiCostAnalysis] = useState<boolean>(false);
  const [disableUrlPowerBiHumanResources, setDisableUrlPowerBiHumanResources] = useState<boolean>(false);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const disableButtonNewCompany = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });
      return true;
    }

    return listCompanies.length >= user.license.quantidade_cnpj;
  }, [addToast, user, listCompanies]);

  const defineCompany = useCallback(async (inValue: ICompany) => {
    const temp = {
      ...inValue,
      periodo_analise_analitica: inValue.periodo_analise_analitica || '0-Ano Corrente',
    };

    setCompany(temp);
    formRef.current?.setData(temp);
    setDisableUrlPowerBi((temp.system_kind & 1) === 0);
    setDisableUrlPowerBiFinancialAccounts((temp.system_kind & 2) === 0);
    setDisableUrlPowerBiCostAnalysis((temp.system_kind & 4) === 0);
    setDisableUrlPowerBiHumanResources((temp.system_kind & 8) === 0);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineCompany(emptyCompany);
  }, [defineCompany]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localCompany = params.row as ICompany;

      setCompanyIndex(listCompanies.indexOf(localCompany));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineCompany(localCompany);
    },
    [listCompanies, defineCompany],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (baseCompany: ICompany | undefined) => {
      if (baseCompany !== undefined) {
        const newCompany: ICompany = {
          acao: baseCompany.acao,
          active: true,
          cnpj: baseCompany.cnpj,
          ie: baseCompany.ie,
          cnpj_estab_tot: baseCompany.cnpj_estab_tot,
          code: baseCompany.code,
          id: '',
          name: baseCompany.name,
          system_kind: baseCompany.system_kind,
          url_powerbi: baseCompany.url_powerbi,
          url_powerbi_cost_analysis: baseCompany.url_powerbi_cost_analysis,
          url_powerbi_financial_accounts: baseCompany.url_powerbi_financial_accounts,
          url_powerbi_human_resources: baseCompany.url_powerbi_human_resources,
          accountinganalysis: baseCompany.accountinganalysis,
          financialaccounts: baseCompany.financialaccounts,
          humanresources: baseCompany.humanresources,
          costanalysis: baseCompany.costanalysis,
          created_user: baseCompany.created_user,
          periodo_analise_analitica: baseCompany.periodo_analise_analitica,
          licence: baseCompany.licence,
        };

        defineCompany(newCompany);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineCompany],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteCompany(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const colunaAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        {!disableButtonNewCompany() && (
          <Tooltip title="Duplicar Registro" placement="top">
            <IconButton
              color="primary"
              aria-label="Duplicar"
              onClick={() => OnGridRowDuplicate(params.row as ICompany)}
            >
              <FiCopy />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as ICompany).id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnSystemKindDescription: GridColDef = {
    headerName: 'Sistema',
    field: 'system_kind',
    width: 500,
    renderCell: (params: GridCellParams) => {
      let valor = 0;
      if (params.value) {
        valor = parseInt(params.value.toLocaleString(), 0);
      }

      return <ComponentDescriptionSystemKind kind={valor} />;
    },
  };

  const columns: GridColDef[] = [
    colunaAcao,
    ColumnCode,
    ColumnCNPJ,
    ColumnIE,
    ColumnCNPJEstabTot,
    ColumnName,
    ColumnId,
    ColumnActive,
    ColumnSystemKindDescription,
    // ColumnPeriodoAnaliseAnalitica,
    ColumnURLPowerBIAccountingAnalysis,
    ColumnURLPowerBIFinancialaccounts,
    ColumnURLPowerBIHumanResources,
    ColumnURLPowerBICostAnalysis,
  ];

  const getCompany = useCallback(async () => {
    const response = await api.get<ICompany[]>(`/company/${user.license.id}`);

    setListCompanies(response.data);

    if (rowToEdit !== null) {
      const findEditCompany = response.data.find(item => item.id === rowToEdit);

      if (findEditCompany) {
        defineCompany(findEditCompany);
        setCompanyIndex(response.data.indexOf(findEditCompany));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }
  }, [rowToEdit, user, defineCompany]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getCompany();

    setOpenLoading(false);
    setHiddenPesquisar(false);
  }, [getCompany]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);

    await api.delete(`/company/company/${idDeleteCompany}`);

    setListCompanies(oldCompany => oldCompany.filter(item => item.id !== idDeleteCompany));

    defineCompany(emptyCompany);

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Registro excluído com sucesso!',
    });

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteCompany, addToast, defineCompany]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListCompanies(oldCompany => oldCompany.filter(item => item.id !== itemId));

          return api.delete(`/company/company/${itemId}`);
        }),
      );

      defineCompany(emptyCompany);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineCompany, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (companyIndex !== undefined) {
      if (companyIndex < listCompanies.length - 1) {
        const localIndex = companyIndex + 1;
        setCompanyIndex(localIndex);
        const findCompany = listCompanies[localIndex];
        if (findCompany) {
          defineCompany(findCompany);
        }
      }
    }
  }, [companyIndex, listCompanies, defineCompany]);

  const onFirstRegister = useCallback(async () => {
    if (listCompanies.length > 0) {
      setCompanyIndex(0);
      const findCompany = listCompanies[0];
      if (findCompany) {
        defineCompany(findCompany);
      }
    }
  }, [listCompanies, defineCompany]);

  const onLastRegister = useCallback(async () => {
    if (listCompanies.length > 0) {
      setCompanyIndex(listCompanies.length - 1);
      const findCompany = listCompanies[listCompanies.length - 1];
      if (findCompany) {
        defineCompany(findCompany);
      }
    }
  }, [listCompanies, defineCompany]);

  const onPriorRegister = useCallback(async () => {
    if (companyIndex !== undefined) {
      if (companyIndex > 0) {
        const localIndex = companyIndex - 1;
        setCompanyIndex(localIndex);
        const findCompany = listCompanies[localIndex];
        if (findCompany) {
          defineCompany(findCompany);
        }
      }
    }
  }, [companyIndex, listCompanies, defineCompany]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});
    if (companyIndex !== undefined) {
      const findCompany = listCompanies[companyIndex];
      if (findCompany) {
        defineCompany(findCompany);
      }
    }
  }, [companyIndex, listCompanies, defineCompany]);

  const handleSubmit = useCallback(
    async (data: ICompany, { reset }) => {
      if (licencaInvalida()) return;

      let system_kind_temp = 0;
      const fieldAccounting = document.getElementById(idaccounting);
      if (fieldAccounting) {
        if ((fieldAccounting as HTMLInputElement).checked) {
          system_kind_temp += 1;
          data.accountinganalysis = true;
        }
      }

      const fieldFinancial = document.getElementById(idfinancial);
      if (fieldFinancial) {
        if ((fieldFinancial as HTMLInputElement).checked) {
          system_kind_temp += 2;
          data.financialaccounts = true;
        }
      }

      const fieldCost = document.getElementById(idcost);
      if (fieldCost) {
        if ((fieldCost as HTMLInputElement).checked) {
          system_kind_temp += 4;
          data.costanalysis = true;
        }
      }

      const fieldHumanResources = document.getElementById(idhumanresources);
      if (fieldHumanResources) {
        if ((fieldHumanResources as HTMLInputElement).checked) {
          system_kind_temp += 8;
          data.humanresources = true;
        }
      }

      data.system_kind = system_kind_temp;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          cnpj: Yup.string()
            .required('CNPJ é obrigatório')
            .test('cnpj-valid', 'CNPJ inválido', value => {
              const isValid = cnpj.isValid(value as string);
              if (!isValid) {
                return false;
              }

              return true;
            })
            .test('exists-company', 'CNPJ já existe em outra empresa', value => {
              const findCompany = listCompanies.find(item => item.cnpj === value);
              if (findCompany && findCompany.id !== data.id) {
                return false;
              }
              return true;
            }),
          cnpj_estab_tot: Yup.string()
            .required('CNPJ é obrigatório')
            .test('cnpj-valid', 'CNPJ inválido', value => {
              return cnpj.isValid(value as string);
            }),
          name: Yup.string().required('Razão social é obrigatória'),
          periodo_analise_analitica: Yup.string().required('Período análise é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        const formData: ICompany = {
          id: data.id,
          acao: data.acao,
          code: data.code,
          cnpj: data.cnpj.replace(/[^0-9]/g, ''),
          ie: data.ie,
          cnpj_estab_tot: data.cnpj_estab_tot.replace(/[^0-9]/g, ''),
          name: data.name,
          active: data.active,
          url_powerbi: data.url_powerbi,
          url_powerbi_cost_analysis: data.url_powerbi_cost_analysis,
          url_powerbi_financial_accounts: data.url_powerbi_financial_accounts,
          url_powerbi_human_resources: data.url_powerbi_human_resources,
          system_kind: data.system_kind,
          created_user: user.id,
          costanalysis: data.costanalysis,
          accountinganalysis: data.accountinganalysis,
          financialaccounts: data.financialaccounts,
          humanresources: data.humanresources,
          periodo_analise_analitica: data.periodo_analise_analitica,
          licence: user.license.id,
        };

        const responseCompany = await api.get<ICompanyLicenseDTO>(`/company/cnpj/${formData.cnpj}`);

        let companyAlreadyExists = false;
        if (responseCompany.data) {
          if (formData.id === undefined || formData.id.trim() === '') {
            companyAlreadyExists = true;
          }

          if (responseCompany.data.id !== data.id) {
            companyAlreadyExists = true;
          }
        }

        if (companyAlreadyExists) {
          setOpenLoading(false);
          addToast({
            type: 'error',
            title: 'Empresa já está vinculada a outra licença',
            description: `Licença: ${responseCompany.data.license_description.trim()}-[${responseCompany.data.id_license.trim()}]`,
          });
          return;
        }

        if (data.id === '') {
          const response = await api.post(`/company`, formData);
          formData.id = response.data.id;

          setListCompanies(oldLicenses => [...oldLicenses, formData]);

          const formDataUserCompany = {
            id_license: user.license.id,
            id_company: response.data.id,
            id_user: user.id,
            company_active: true,
          };
          await api.post(`/usercompany`, formDataUserCompany);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/company/company/${data.id}`, data);

          setListCompanies(getListUpdate(listCompanies, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        if (!disableButtonNewCompany()) {
          reset();
        }

        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setOpenLoading(false);
      }
    },
    [licencaInvalida, listCompanies, user, addToast, disableButtonNewCompany],
  );

  const clearInputValue = (id: string): void => {
    const fieldInput = document.getElementById(id);
    if (fieldInput) {
      (fieldInput as HTMLInputElement).value = '';
    }
  };

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>

          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          {!disableButtonNewCompany() && (
            <Tooltip title="Novo Registro" placement="top">
              <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
                <GoPlus />
                Novo
              </TabButtonNovo>
            </Tooltip>
          )}

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteCompany('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>

          <Tooltip title="Solicitar licenças" placement="top">
            <span>
              <span>
                <TabButtonWhatsApp
                  id="getMoreLicenses"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?1=pt_BR&phone=555431968474"
                >
                  <AiOutlineWhatsApp />
                </TabButtonWhatsApp>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <SpanLicencas>
          CNPJ's contratados: <SpanLicencasNumber>{user.license.quantidade_cnpj}</SpanLicencasNumber>
          CNPJ's cadastrados: <SpanLicencasNumber>{listCompanies.length}</SpanLicencasNumber>
          CNPJ's disponíveis:{' '}
          <SpanLicencasNumber>{user.license.quantidade_cnpj - listCompanies.length}</SpanLicencasNumber>
        </SpanLicencas>

        <TabTitulo>
          <span>E M P R E S A S</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listCompanies}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
            />
          </ThemeProvider>
          ;
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={company}>
          <FormContainer>
            <FormNavigate>
              {!disableButtonNewCompany() && (
                <Tooltip title="Novo Registro" placement="top">
                  <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                    <AiOutlinePlus />
                  </IconButton>
                </Tooltip>
              )}

              {!disableButtonNewCompany() && (
                <Tooltip title="Duplicar Registro" placement="top">
                  <IconButton color="primary" aria-label="Duplicar" onClick={() => OnGridRowDuplicate(company)}>
                    <MdContentCopy />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(company ? company.id : '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              {(!disableButtonNewCompany() || company?.id) && (
                <Tooltip title="Salvar" placement="top">
                  <IconButton type="submit" color="primary" aria-label="Confirmar">
                    <AiOutlineSave />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFieldCheckbox field="T300px">
                <Checkbox name="active" />
                <span>Ativa</span>
              </ContainerFieldCheckbox>

              <ContainerFields field="T200px">
                <span>Código:</span>
                <InputComum autoFocus name="code" />
              </ContainerFields>

              <ContainerFieldCnpj>
                <ContainerFields field="T300px">
                  <span>CNPJ:</span>
                  <InputComum name="cnpj" maxLength={14} />
                </ContainerFields>

                <ContainerFields field="T300px" className="field2">
                  <span>Inscrição Estadual:</span>
                  <InputComum maxLength={14} name="ie" />
                </ContainerFields>
              </ContainerFieldCnpj>

              <ContainerFields field="T300px">
                <span>Usar Configurações do CNPJ:</span>
                <InputComum maxLength={14} name="cnpj_estab_tot" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Razão Social</span>
                <InputComum name="name" />
              </ContainerFields>
              <ContainerFieldCnpj>
                <ContainerFieldCheckbox field="T400px">
                  <Checkbox
                    id={idaccounting}
                    name="accountinganalysis"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = !(e.target as HTMLInputElement).checked;
                      setDisableUrlPowerBi(localValue);
                      if (localValue) {
                        clearInputValue('url_powerbi');
                      }
                    }}
                  />
                  <span>Accounting Analysis</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox field="T400px" className="field2">
                  <Checkbox
                    id={idfinancial}
                    name="financialaccounts"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = !(e.target as HTMLInputElement).checked;
                      setDisableUrlPowerBiFinancialAccounts(localValue);
                      if (localValue) {
                        clearInputValue('url_powerbi_financial_accounts');
                      }
                    }}
                  />
                  <span>Financial Accounts</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox field="T400px" className="field2">
                  <Checkbox
                    id={idhumanresources}
                    name="humanresources"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = !(e.target as HTMLInputElement).checked;
                      setDisableUrlPowerBiHumanResources(localValue);
                      if (localValue) {
                        clearInputValue('url_powerbi_human_resources');
                      }
                    }}
                  />
                  <span>Human Resources</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox field="T300px" className="field2">
                  <Checkbox
                    id={idcost}
                    name="costanalysis"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const localValue = !(e.target as HTMLInputElement).checked;
                      setDisableUrlPowerBiCostAnalysis(localValue);
                      if (localValue) {
                        clearInputValue('url_powerbi_cost_analysis');
                      }
                    }}
                  />
                  <span>Reports BI</span>
                </ContainerFieldCheckbox>
              </ContainerFieldCnpj>

              {/* <ContainerFields field="T300px">
                <span>Período Análise Analítica:</span>
                <Select name="periodo_analise_analitica">
                  {options_periodo_analise_analitica.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </ContainerFields> */}

              <ContainerFields field="T100" className={disableUrlPowerBi ? 'desabilitado' : ''}>
                <span>URL Power BI - Accounting Analysis</span>
                <InputComum disabled={disableUrlPowerBi} id="url_powerbi" name="url_powerbi" />
              </ContainerFields>

              <ContainerFields field="T100" className={disableUrlPowerBiFinancialAccounts ? 'desabilitado' : ''}>
                <span>URL Power BI - Financial Accounts</span>
                <InputComum
                  id="url_powerbi_financial_accounts"
                  name="url_powerbi_financial_accounts"
                  disabled={disableUrlPowerBiFinancialAccounts}
                />
              </ContainerFields>

              <ContainerFields field="T100" className={disableUrlPowerBiHumanResources ? 'desabilitado' : ''}>
                <span>URL Power BI - Human Resources</span>
                <InputComum
                  id="url_powerbi_human_resources"
                  name="url_powerbi_human_resources"
                  disabled={disableUrlPowerBiHumanResources}
                />
              </ContainerFields>

              <ContainerFields field="T100" className={disableUrlPowerBiCostAnalysis ? 'desabilitado' : ''}>
                <span>URL Power BI - Reports BI</span>
                <InputComum
                  id="url_powerbi_cost_analysis"
                  name="url_powerbi_cost_analysis"
                  disabled={disableUrlPowerBiCostAnalysis}
                />
              </ContainerFields>

              <ContainerFields field="TID">
                <InputID name="id" hidden disabled />
                <InputID name="periodo_analise_analitica" hidden disabled />
              </ContainerFields>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default Company;
