import React, { useRef, useState, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { DataGrid, GridColDef, GridCellParams, GridRowId, ptBR } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiCopy } from 'react-icons/fi';
import { MdDelete, MdEdit, MdClear, MdSkipNext, MdSkipPrevious, MdContentCopy } from 'react-icons/md';
import {
  AiOutlinePlus,
  AiOutlineSave,
  AiFillFastBackward,
  AiFillFastForward,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';

import InputComum from '../../components/InputComum';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import Header from '../../components/Header';
import FormModalDialogConfirm from '../../components/FormModalDialogConfirm';

import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getGenericListUpdate from '../../utils/getGenericListUpdate';
import getValidationErrors from '../../utils/getValidationErrors';
import { getPathGrid } from '../../utils/storagePath';

import {
  ColumnCodIndicador,
  ColumnCodIndicadorSup,
  ColumnGrupoIndicador,
  ColumnTipoIndicador,
  ColumnDescIndicador,
  ColumnNomeGestor,
  ColumnNomeAuditor,
  ColumnInformacao01,
  ColumnInformacao02,
  ColumnInformacao03,
  ColumnInformacao04,
  ColumnInformacao05,
  ColumnObs,
  ColumnIdCadIndicPerson,
} from './GridColumns';

import ICadIndicadorPersonalizado, { emptyCadIndicadorPersonalizado } from './ICadIndicadorPersonalizado';
import {
  Container,
  ContainerFields,
  Tab,
  TabButton,
  TabContent,
  FormFields,
  FormContainer,
  FormNavigate,
  TabTitulo,
  TabButtonArea,
  TabButtonNovo,
  TabButtonDelete,
  TabButtonGridRowHeight,
  ContainerFlexRow,
  ContainerFieldCheckbox,
  ContainerFieldsHidden,
} from './styles';

const useStylesLoading = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 2000,
      color: '#fff',
    },
  }),
);

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const titulo = 'C A D A S T R O    I N D I C A D O R E S';
const idaccounting = 'accountingAnalysis';
const idfinancial = 'financialAccounts';
const idcost = 'costAnalysis';
const idhumanresources = 'humanResources';

const options_tipo = [
  { value: '1-Valor (R$)', label: '1-Valor (R$)' },
  { value: '2-Percentual (%)', label: '2-Percentual (%)' },
  { value: '3-Quantidade', label: '3-Quantidade' },
];

const options_upload_ftp = [
  { value: 0, label: '00-Nenhum' },
  { value: 1, label: '01-JSON' },
  { value: 2, label: '02-XML' },
  { value: 3, label: '03-HTML' },
  { value: 4, label: '04-TXT' },
  { value: 5, label: '05-CSV' },
  { value: 6, label: '06-XLS' },
  { value: 7, label: '07-XLSX' },
  { value: 8, label: '08-XLSM' },
  { value: 99, label: '99-Outros' },
];

const CadIndicadorPersonalizado: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [cadIndicadorPersonalizado, setCadIndicadorPersonalizado] = useState<ICadIndicadorPersonalizado>();
  const [listCadIndicadorPersonalizado, setListCadIndicadorPersonalizado] = useState<ICadIndicadorPersonalizado[]>([]);
  const [hiddenPesquisar, setHiddenPesquisar] = useState<boolean>(false);
  const [hiddenCadastrar, setHiddenCadastrar] = useState<boolean>(true);
  const [cadIndicadorPersonalizadoIndex, setCadIndicadorPersonalizadoIndex] = useState<number>(0);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [idDeleteCadIndicadorPersonalizado, setIdDeleteCadIndicadorPersonalizado] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const cssLoading = useStylesLoading();
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const licencaInvalida = useCallback((): boolean => {
    if (!user.license || user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [addToast, user]);

  const defineCadIndicadorPersonalizado = useCallback(async (data: ICadIndicadorPersonalizado) => {
    setCadIndicadorPersonalizado(data);
    formRef.current?.setData(data);
  }, []);

  const onNewRegister = useCallback(async () => {
    defineCadIndicadorPersonalizado(emptyCadIndicadorPersonalizado);
  }, [defineCadIndicadorPersonalizado]);

  const OnGridSetRegisterToEdit = useCallback(
    async (params: GridCellParams) => {
      const localCadHistPadrao = params.row as ICadIndicadorPersonalizado;

      setCadIndicadorPersonalizadoIndex(listCadIndicadorPersonalizado.indexOf(localCadHistPadrao));
      setHiddenCadastrar(false);
      setHiddenPesquisar(true);
      setRowToEdit(null);
      defineCadIndicadorPersonalizado(localCadHistPadrao);
    },
    [listCadIndicadorPersonalizado, defineCadIndicadorPersonalizado],
  );

  const OnSetRegisterToAdd = useCallback(async () => {
    onNewRegister();
    setHiddenCadastrar(false);
    setHiddenPesquisar(true);
  }, [onNewRegister]);

  const OnGridRowDuplicate = useCallback(
    async (inSource: ICadIndicadorPersonalizado | undefined) => {
      if (inSource !== undefined) {
        const newCadIndicadorPersonalizado: ICadIndicadorPersonalizado = {
          acao: inSource.acao,
          id_license: inSource.id_license,
          id_cad_indic_person: '',
          cod_indicador_sup: inSource.cod_indicador_sup,
          cod_indicador: inSource.cod_indicador,
          created_user: inSource.created_user,
          desc_indicador: inSource.desc_indicador,
          grupo_indicador: inSource.grupo_indicador,
          nome_auditor: inSource.nome_auditor,
          nome_gestor: inSource.nome_gestor,
          sistema: inSource.sistema,
          tipo_indicador: inSource.tipo_indicador,
          upload_ftp: inSource.upload_ftp,
          informacao01: inSource.informacao01,
          informacao02: inSource.informacao02,
          informacao03: inSource.informacao03,
          informacao04: inSource.informacao04,
          informacao05: inSource.informacao05,
          obs: inSource.obs,
          kind_interface: 'cad_indic_person',
          accountinganalysis: inSource.accountinganalysis,
          financialaccounts: inSource.financialaccounts,
          humanresources: inSource.humanresources,
          costanalysis: inSource.costanalysis,
        };

        defineCadIndicadorPersonalizado(newCadIndicadorPersonalizado);
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
      }
    },
    [defineCadIndicadorPersonalizado],
  );

  const OnConfirmationDeleteSingle = useCallback(
    async (id: string) => {
      if (licencaInvalida()) return;

      if (id === '') return;

      setIdDeleteCadIndicadorPersonalizado(id);
      setOpenDeleteSingle(true);
    },
    [licencaInvalida],
  );

  const ColumnAcao: GridColDef = {
    field: 'acao',
    headerName: 'Ação',
    editable: false,
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    width: 160,
    renderCell: (params: GridCellParams) => (
      <strong>
        <Tooltip title="Editar Registro" placement="top">
          <IconButton color="primary" aria-label="Editar" onClick={() => OnGridSetRegisterToEdit(params)}>
            <MdEdit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duplicar Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Duplicar"
            onClick={() => OnGridRowDuplicate(params.row as ICadIndicadorPersonalizado)}
          >
            <FiCopy />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir Registro" placement="top">
          <IconButton
            color="primary"
            aria-label="Excluir"
            onClick={() => OnConfirmationDeleteSingle((params.row as ICadIndicadorPersonalizado).id_cad_indic_person)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      </strong>
    ),
  };

  const ColumnUploadFtp: GridColDef = {
    headerName: 'Upload arquivo',
    field: 'upload_ftp',
    width: 190,
    renderCell: (params: GridCellParams) => {
      let valor = 0;
      if (params.value) {
        valor = parseInt(params.value.toLocaleString(), 0);
      }

      const findItem = options_upload_ftp.find(itemList => itemList.value === valor);
      if (findItem) {
        return <span>{findItem.label}</span>;
      }

      return <span />;
    },
  };

  const columns: GridColDef[] = [
    ColumnAcao,
    ColumnCodIndicador,
    ColumnCodIndicadorSup,
    ColumnGrupoIndicador,
    ColumnTipoIndicador,
    ColumnUploadFtp,
    ColumnDescIndicador,
    ColumnNomeGestor,
    ColumnNomeAuditor,
    ColumnInformacao01,
    ColumnInformacao02,
    ColumnInformacao03,
    ColumnInformacao04,
    ColumnInformacao05,
    ColumnObs,
    ColumnIdCadIndicPerson,
  ];

  const getCadIndicadorPersonalizado = useCallback(async () => {
    if (licencaInvalida()) return;

    const response = await api.post<ICadIndicadorPersonalizado[]>(`/cadindicadorpersonalizado/v1/filter`, {
      id_license: user.license.id,
    });

    setListCadIndicadorPersonalizado(response.data);

    if (rowToEdit !== null) {
      const findEditCadIndicadorPersonalizado = response.data.find(item => item.id_cad_indic_person === rowToEdit);

      if (findEditCadIndicadorPersonalizado) {
        defineCadIndicadorPersonalizado(findEditCadIndicadorPersonalizado);
        setCadIndicadorPersonalizadoIndex(response.data.indexOf(findEditCadIndicadorPersonalizado));
        setHiddenCadastrar(false);
        setHiddenPesquisar(true);
        setRowToEdit(null);
      }
    }

    setHiddenPesquisar(false);
  }, [user, licencaInvalida, rowToEdit, defineCadIndicadorPersonalizado]);

  const getValues = useCallback(async () => {
    setOpenLoading(true);

    await getCadIndicadorPersonalizado();

    setOpenLoading(false);
  }, [getCadIndicadorPersonalizado]);

  useEffect(() => {
    getValues();

    const rowHeight = localStorage.getItem(getPathGrid());
    if (rowHeight) {
      setGridRowHeight(parseInt(rowHeight, 10));
    }
  }, [getValues]);

  const handleOnRowDeleteSingle = useCallback(async () => {
    setOpenLoading(true);
    let response = null;
    try {
      response = await api.delete(`/cadindicadorpersonalizado/v1/${idDeleteCadIndicadorPersonalizado}`);
      if (response.status !== 500) {
        setListCadIndicadorPersonalizado(oldValue =>
          oldValue.filter(item => item.id_cad_indic_person !== idDeleteCadIndicadorPersonalizado),
        );

        defineCadIndicadorPersonalizado(emptyCadIndicadorPersonalizado);

        addToast({
          type: 'success',
          title: 'Removido',
          description: 'Registro excluído com sucesso!',
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      addToast({
        type: 'error',
        title: 'Erro ao remover o indicador',
        description: err.response?.data.msg.message,
      });
    }

    setOpenDeleteSingle(false);
    setOpenLoading(false);
  }, [idDeleteCadIndicadorPersonalizado, addToast, defineCadIndicadorPersonalizado]);

  const handleOnRowDeleteMultiple = useCallback(async () => {
    setOpenLoading(true);
    if (selectedIds.length > 0) {
      await Promise.all(
        selectedIds.map(itemId => {
          setListCadIndicadorPersonalizado(oldValue => oldValue.filter(item => item.id_cad_indic_person !== itemId));

          return api.delete(`/cadindicadorpersonalizado/v1/${itemId}`);
        }),
      );

      defineCadIndicadorPersonalizado(emptyCadIndicadorPersonalizado);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Registro excluído com sucesso!',
      });
    }

    setSelectedIds([]);
    setOpenDeleteMultiple(false);
    setOpenLoading(false);
  }, [addToast, defineCadIndicadorPersonalizado, selectedIds]);

  const handleClick = (id: string): void => {
    setHiddenCadastrar(id === 'pesquisar');
    setHiddenPesquisar(id === 'cadastrar');
  };

  const onNextRegister = useCallback(async () => {
    if (cadIndicadorPersonalizadoIndex !== undefined) {
      if (cadIndicadorPersonalizadoIndex < listCadIndicadorPersonalizado.length - 1) {
        const localIndex = cadIndicadorPersonalizadoIndex + 1;
        setCadIndicadorPersonalizadoIndex(localIndex);
        const findItem = listCadIndicadorPersonalizado[localIndex];
        if (findItem) {
          defineCadIndicadorPersonalizado(findItem);
        }
      }
    }
  }, [cadIndicadorPersonalizadoIndex, listCadIndicadorPersonalizado, defineCadIndicadorPersonalizado]);

  const onFirstRegister = useCallback(async () => {
    if (listCadIndicadorPersonalizado.length > 0) {
      setCadIndicadorPersonalizadoIndex(0);
      const findItem = listCadIndicadorPersonalizado[0];
      if (findItem) {
        defineCadIndicadorPersonalizado(findItem);
      }
    }
  }, [listCadIndicadorPersonalizado, defineCadIndicadorPersonalizado]);

  const onLastRegister = useCallback(async () => {
    if (listCadIndicadorPersonalizado.length > 0) {
      setCadIndicadorPersonalizadoIndex(listCadIndicadorPersonalizado.length - 1);
      const findItem = listCadIndicadorPersonalizado[listCadIndicadorPersonalizado.length - 1];
      if (findItem) {
        defineCadIndicadorPersonalizado(findItem);
      }
    }
  }, [listCadIndicadorPersonalizado, defineCadIndicadorPersonalizado]);

  const onPriorRegister = useCallback(async () => {
    if (cadIndicadorPersonalizadoIndex !== undefined) {
      if (cadIndicadorPersonalizadoIndex > 0) {
        const localIndex = cadIndicadorPersonalizadoIndex - 1;
        setCadIndicadorPersonalizadoIndex(localIndex);
        const findItem = listCadIndicadorPersonalizado[localIndex];
        if (findItem) {
          defineCadIndicadorPersonalizado(findItem);
        }
      }
    }
  }, [cadIndicadorPersonalizadoIndex, listCadIndicadorPersonalizado, defineCadIndicadorPersonalizado]);

  const onCancelRegister = useCallback(async () => {
    formRef.current?.setErrors({});

    if (cadIndicadorPersonalizadoIndex !== undefined) {
      const findEditCadHistPadrao = listCadIndicadorPersonalizado[cadIndicadorPersonalizadoIndex];
      if (findEditCadHistPadrao) {
        defineCadIndicadorPersonalizado(findEditCadHistPadrao);
      }
    }

    onNewRegister();
  }, [cadIndicadorPersonalizadoIndex, listCadIndicadorPersonalizado, onNewRegister, defineCadIndicadorPersonalizado]);

  const isDuplicated = useCallback(
    (inId: string, inCode: string): boolean => {
      const findItem = listCadIndicadorPersonalizado.find(
        itemList => itemList.cod_indicador === inCode && itemList.id_cad_indic_person !== inId,
      );
      if (findItem) return true;
      return false;
    },
    [listCadIndicadorPersonalizado],
  );

  const handleSubmit = useCallback(
    async (data: ICadIndicadorPersonalizado, { reset }) => {
      if (licencaInvalida()) return;

      let system_kind_temp = 0;
      const fieldAccounting = document.getElementById(idaccounting);
      if (fieldAccounting) {
        if ((fieldAccounting as HTMLInputElement).checked) {
          system_kind_temp += 1;
          data.accountinganalysis = true;
        }
      }

      const fieldFinancial = document.getElementById(idfinancial);
      if (fieldFinancial) {
        if ((fieldFinancial as HTMLInputElement).checked) {
          system_kind_temp += 2;
          data.financialaccounts = true;
        }
      }

      const fieldCost = document.getElementById(idcost);
      if (fieldCost) {
        if ((fieldCost as HTMLInputElement).checked) {
          system_kind_temp += 4;
          data.costanalysis = true;
        }
      }

      const fieldHumanResources = document.getElementById(idhumanresources);
      if (fieldHumanResources) {
        if ((fieldHumanResources as HTMLInputElement).checked) {
          system_kind_temp += 8;
          data.humanresources = true;
        }
      }

      data.sistema = system_kind_temp;

      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          grupo_indicador: Yup.string().required('Grupo é obrigatório'),
          tipo_indicador: Yup.string().required('Tipo é obrigatório'),
          upload_ftp: Yup.string().required('Upload arquivo é obrigatório'),
          desc_indicador: Yup.string().required('Descrição é obrigatória'),
          sistema: Yup.string().required('Sistema é obrigatório'),
          cod_indicador: Yup.string()
            .required('Código é obrigatório')
            .test('code-valid', 'Código do indicador já existe', value => {
              return !isDuplicated(data.id_cad_indic_person, value as string);
            }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setOpenLoading(true);
        if (data.id_cad_indic_person === '') {
          const formData: ICadIndicadorPersonalizado = {
            id_cad_indic_person: data.id_cad_indic_person,
            id_license: user.license.id,
            cod_indicador: data.cod_indicador,
            cod_indicador_sup: data.cod_indicador_sup,
            desc_indicador: data.desc_indicador,
            grupo_indicador: data.grupo_indicador,
            informacao01: data.informacao01,
            informacao02: data.informacao02,
            informacao03: data.informacao03,
            informacao04: data.informacao04,
            informacao05: data.informacao05,
            nome_auditor: data.nome_auditor,
            nome_gestor: data.nome_gestor,
            tipo_indicador: data.tipo_indicador,
            upload_ftp: parseInt(data.upload_ftp.toString(), 10),
            sistema: data.sistema,
            obs: data.obs,
            acao: data.acao,
            created_user: user.id,
            kind_interface: 'cad_indic_person',
            accountinganalysis: data.accountinganalysis,
            financialaccounts: data.financialaccounts,
            humanresources: data.humanresources,
            costanalysis: data.costanalysis,
          };

          const response = await api.post(`/cadindicadorpersonalizado/v1`, formData);
          formData.id_cad_indic_person = response.data.id_cad_indic_person;
          setListCadIndicadorPersonalizado(oldValues => [...oldValues, formData]);

          addToast({
            type: 'success',
            title: 'Adicionado',
            description: 'Registro adicionado com sucesso!',
          });
        } else {
          await api.put(`/cadindicadorpersonalizado/v1/${data.id_cad_indic_person}`, data);

          setListCadIndicadorPersonalizado(getGenericListUpdate(listCadIndicadorPersonalizado, data));

          addToast({
            type: 'success',
            title: 'Alterado',
            description: 'Registro alterado com sucesso!',
          });
        }

        reset();
        setOpenLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setOpenLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreum um erro ao salvar o registro!',
        });
      }
    },
    [licencaInvalida, listCadIndicadorPersonalizado, user, addToast, isDuplicated],
  );

  return (
    <Container>
      <Header />

      <Tab>
        <TabButtonArea>
          <TabButton id="pesquisar" isActive={!hiddenPesquisar} onClick={() => handleClick('pesquisar')}>
            Listagem
          </TabButton>
          <TabButton id="cadastrar" isActive={!hiddenCadastrar} onClick={() => handleClick('cadastrar')}>
            Cadastro
          </TabButton>
          <Tooltip title="Reduzir o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightdown" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Aumentar o tamanho da linha" placement="top">
            <TabButtonGridRowHeight id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </TabButtonGridRowHeight>
          </Tooltip>

          <Tooltip title="Novo Registro" placement="top">
            <TabButtonNovo id="new_register" onClick={() => OnSetRegisterToAdd()}>
              <GoPlus />
              Novo
            </TabButtonNovo>
          </Tooltip>

          <Tooltip title="Remover Registros Selecionados" placement="top">
            <span>
              <span hidden={selectedIds.length <= 0}>
                <TabButtonDelete
                  id="remove_all"
                  onClick={() => {
                    setIdDeleteCadIndicadorPersonalizado('');
                    setOpenDeleteMultiple(true);
                  }}
                >
                  <RiDeleteBinLine />
                  Remover
                </TabButtonDelete>
              </span>
            </span>
          </Tooltip>
        </TabButtonArea>

        <TabTitulo>
          <span>{titulo}</span>
        </TabTitulo>
      </Tab>

      <TabContent id="pesquisar" isActive={!hiddenPesquisar}>
        <div style={{ height: '85vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={listCadIndicadorPersonalizado}
              columns={columns}
              rowHeight={gridRowHeight}
              checkboxSelection
              selectionModel={selectedIds}
              disableSelectionOnClick
              onSelectionModelChange={e => setSelectedIds(e.selectionModel)}
              getRowId={r => r.id_cad_indic_person}
            />
          </ThemeProvider>
        </div>
      </TabContent>

      <TabContent id="cadasto" isActive={!hiddenCadastrar}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={cadIndicadorPersonalizado}>
          <FormContainer>
            <FormNavigate>
              <Tooltip title="Novo Registro" placement="top">
                <IconButton color="primary" aria-label="adicionar" onClick={() => onNewRegister()}>
                  <AiOutlinePlus />
                </IconButton>
              </Tooltip>

              <Tooltip title="Duplicar Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Duplicar"
                  onClick={() => OnGridRowDuplicate(cadIndicadorPersonalizado)}
                >
                  <MdContentCopy />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir Registro" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => OnConfirmationDeleteSingle(cadIndicadorPersonalizado?.id_cad_indic_person || '')}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </Tooltip>

              <Tooltip title="Cancelar" placement="top">
                <IconButton type="reset" color="primary" aria-label="Cancelar" onClick={() => onCancelRegister()}>
                  <MdClear />
                </IconButton>
              </Tooltip>

              <Tooltip title="Salvar" placement="top">
                <IconButton type="submit" color="primary" aria-label="Confirmar">
                  <AiOutlineSave />
                </IconButton>
              </Tooltip>

              <Tooltip title="Primeiro Registro" placement="top">
                <IconButton color="primary" aria-label="Primeiro" onClick={() => onFirstRegister()}>
                  <AiFillFastBackward />
                </IconButton>
              </Tooltip>

              <Tooltip title="Registro Anterior" placement="top">
                <IconButton color="primary" aria-label="Anterior" onClick={() => onPriorRegister()}>
                  <MdSkipPrevious />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próximo Registro" placement="top">
                <IconButton color="primary" aria-label="Próximo" onClick={() => onNextRegister()}>
                  <MdSkipNext />
                </IconButton>
              </Tooltip>

              <Tooltip title="Último Registro" placement="top">
                <IconButton color="primary" aria-label="Último registro" onClick={() => onLastRegister()}>
                  <AiFillFastForward />
                </IconButton>
              </Tooltip>
            </FormNavigate>

            <FormFields>
              <ContainerFlexRow>
                <ContainerFieldCheckbox field="T400px">
                  <Checkbox id={idaccounting} name="accountinganalysis" />
                  <span>Accounting Analysis</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox field="T400px" className="field2">
                  <Checkbox id={idfinancial} name="financialaccounts" />
                  <span>Financial Accounts</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox field="T400px" className="field2">
                  <Checkbox id={idhumanresources} name="humanresources" />
                  <span>Human Resources</span>
                </ContainerFieldCheckbox>
                <ContainerFieldCheckbox field="T300px" className="field2">
                  <Checkbox id={idcost} name="costanalysis" />
                  <span>Reports BI</span>
                </ContainerFieldCheckbox>
              </ContainerFlexRow>

              <ContainerFields field="T100">
                <span>Grupo do indicador:</span>
                <InputComum name="grupo_indicador" />
              </ContainerFields>

              <ContainerFlexRow
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <ContainerFields field="T200px" className="fieldmr">
                  <span>Código:</span>
                  <InputComum
                    autoFocus
                    name="cod_indicador"
                    style={{
                      height: '22px',
                      textTransform: 'uppercase',
                    }}
                  />
                </ContainerFields>

                <ContainerFields field="T300px" className="fieldmr">
                  <span>Código do indicador superior:</span>
                  <InputComum
                    autoFocus
                    name="cod_indicador_sup"
                    style={{
                      height: '22px',
                      textTransform: 'uppercase',
                    }}
                  />
                </ContainerFields>

                <ContainerFields field="T200px" className="fieldmr">
                  <span>Tipo:</span>
                  <Select name="tipo_indicador" defaultValue="1-Valor (R$)">
                    {options_tipo.map(itemList => (
                      <option key={itemList.value} value={itemList.value}>
                        {itemList.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>

                <ContainerFields field="T200px">
                  <span>Upload arquivo:</span>
                  <Select name="upload_ftp" defaultValue={0}>
                    {options_upload_ftp.map(itemList => (
                      <option key={itemList.value} value={itemList.value}>
                        {itemList.label}
                      </option>
                    ))}
                  </Select>
                </ContainerFields>
              </ContainerFlexRow>

              <ContainerFields field="T100">
                <span>Descrição indicador:</span>
                <InputComum name="desc_indicador" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Nome do gestor:</span>
                <InputComum name="nome_gestor" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Nome do auditor:</span>
                <InputComum name="nome_auditor" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Observação:</span>
                <InputComum name="obs" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 1:</span>
                <InputComum name="informacao01" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 2:</span>
                <InputComum name="informacao02" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 3:</span>
                <InputComum name="informacao03" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 4:</span>
                <InputComum name="informacao04" />
              </ContainerFields>

              <ContainerFields field="T100">
                <span>Informação 5:</span>
                <InputComum name="informacao05" />
              </ContainerFields>

              <ContainerFieldsHidden>
                <InputComum name="id_cad_indic_person" hidden />
                <InputComum name="kind_interface" hidden />
                <InputComum name="id_license" hidden />
                <InputComum name="acao" hidden />
              </ContainerFieldsHidden>
            </FormFields>
          </FormContainer>
        </Form>
      </TabContent>

      <FormModalDialogConfirm
        open={openDeleteSingle}
        onClose={() => setOpenDeleteSingle(false)}
        onConfirm={() => handleOnRowDeleteSingle()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir?"
      />

      <FormModalDialogConfirm
        open={openDeleteMultiple}
        onClose={() => setOpenDeleteMultiple(false)}
        onConfirm={() => handleOnRowDeleteMultiple()}
        buttonCancelText="Cancelar"
        buttonConfirmText="Sim"
        formTitle="Excluir?"
        messageConfirm="Tem certeza que deseja excluir todos os registros selecionados?"
      />

      <Backdrop className={cssLoading.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default CadIndicadorPersonalizado;
